import request from "../utils/requst"

export const getwebsiteEsListUrl = params =>{
    return request({
        method:'GET',
        url:'procedure/website-ec-serve-work-order/page',
        params,
    })
}

export const getOnewebsitetoecUrl = params =>{
    return request({
        method:'GET',
        url:'procedure/website-ec-serve-work-order/get',
        params,
    })
}

export const websitecompleteUrl = data => {
    return request({
        method: 'POST',
        url: 'procedure/website-ec-serve-work-order/complete',
        data,
    })
}

export const websitereopenUrl = data => {
    return request({
        method: 'POST',
        url: 'procedure/website-ec-serve-work-order/technical_support_work_order/reopen',
        data,
    })
}

export const transformUrl = data => {
    return request({
        method: 'POST',
        url: 'procedure/website-ec-serve-work-order/transform',
        data,
    })
}

export const transformFollowPersonUrl = data => {
    return request({
        method: 'POST',
        url: 'procedure/website-ec-serve-work-order/transformFollowPerson',
        data,
    })
}

export const EditaeOrderUrl = data => {
    return request({
        method: 'POST',
        url: 'procedure/website-ec-serve-work-order/edit',
        data,
    })
}
