<template>
  <el-container class="maindiv">
    <el-dropdown class="jueduixuanfu" icon="null">
      <span class="el-dropdown-link">
        {{ username }}
        <i class="el-icon-arrow-down el-icon--right"></i>
      </span>
      <!-- native将原生事件绑定到组件 -->
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native="onLogout"> {{ $t("yhtc") }}</el-dropdown-item>
        <el-dropdown-item @click.native="openChange"> {{ $t("mmxg") }}</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <el-aside class="aside" width="auto">
      <AppAside class="aside-menu" :isCollapse="isCollapse" />
    </el-aside>
    <el-container>
      <el-main class="main">
        <router-view />
      </el-main>
    </el-container>
    <el-dialog :title="this.$t('mmxg')" :visible.sync="showchangepsw">
      <el-form :model="form">
        <el-form-item :label="this.$t('yuanmima')">
          <el-input v-model="form.oldPassword" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :label="this.$t('xinmima')">
          <el-input v-model="form.newPassword" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showchangepsw = false">{{ $t("quxiao") }}</el-button>
        <el-button type="primary" @click="onChangePSWD">{{ $t("queding") }}</el-button>
      </div>
    </el-dialog>
  </el-container>
</template>
<script>
import AppAside from "./components/aside.vue";
import { changePassWord } from "@/api/admin";
export default {
  components: {
    AppAside,
  },

  data() {
    return {
      username: "更多操作",
      isCollapse: false,
      showchangepsw: false,
      form: {
        oldPassword: "",
        newPassword: "",
      },
    };
  },

  mounted() {
    this.username = JSON.parse(window.localStorage.getItem("userdata")).name;
  },

  methods: {
    onLogout() {
      this.$confirm(this.$t("sftc"), this.$t("tixing"), {
        confirmButtonText: this.$t("shi"),
        cancelButtonText: this.$t("fou"),
        type: "warning",
      })
        .then(() => {
          localStorage.clear();
          this.$router.push({
            name: "Login",
          });
        })
        .catch(() => {
          
        });
    },
    openChange() {
      this.showchangepsw = true;
    },
    async onChangePSWD() {
      try {
        const res = await changePassWord(this.form);
        if (res.data.code === 200) {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.showchangepsw = false;
      }
    },
  },
};
</script>
<style lang='less' scoped>
.maindiv {
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.aside {
  background-color: #002033;
  .aside-menu {
    height: 100%;
  }
}
.main {
  background-color: #e9eef3;
}
.header {
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px #ccc;
}
//滚动条隐藏
::-webkit-scrollbar {
  width: 0 !important;
}
::-webkit-scrollbar {
  width: 0 !important;
  height: 0;
}
.jueduixuanfu {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 30px;
  color: #000000;
  font-size: 15px;
  background-color: #ffffff;
  z-index: 1;
  position: absolute;
  top: 90%;
  left: 2%;
  background-color: #ffffff;
  width: 100px;
  height: 50px;
  border-color: #9ec8db;
  border-width: medium;
  border-style: solid;
  border-radius: 10px 10px  10px 10px ;
}
</style>