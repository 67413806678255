<template>
  <div class="adminmain">
    <el-image
      v-if="isgly == false"
      class="image"
      :src="require('@/assets/img/mainbackpage.png')"
    />

    <el-row v-if="isgly == true">
      <el-row>
        <LastMonthsEecharts />
      </el-row>

      <el-row>
        <el-col :span="12">
          <ChannelTypeEcharts />
        </el-col>
        <el-col :span="12">
          <ProblemTypeEcharts />
        </el-col>
      </el-row>

      <el-row>
        <FAEecharts />
      </el-row>
      <el-row>
        <Top10echarts />
      </el-row>
    </el-row>
  </div>
</template>

<script>
import LastMonthsEecharts from "./components/LastMonthsEecharts";
import ChannelTypeEcharts from "./components/ChannelTypeEcharts";
import ProblemTypeEcharts from "./components/ProblemTypeEcharts";
import FAEecharts from "./components/FAEecharts";
import Top10echarts from "./components/Top10echarts";

export default {
  mounted() {
    // if (JSON.parse(window.localStorage.getItem("userdata")).name == "管理员") {
    //   this.isgly = true;
    // } else {
    //   this.isgly = false;
    // }
    if (
      JSON.parse(localStorage.getItem("userdata"))
        .permInfos.map(({ perms }) => perms)
        .includes("bulletin-board")
    ) {
      this.isgly = true;
    }
    else {
      this.isgly = false;
    }
  },
  data() {
    return {
      options: [],
      isgly: null,
    };
  },
  components: {
    LastMonthsEecharts,
    ChannelTypeEcharts,
    ProblemTypeEcharts,
    FAEecharts,
    Top10echarts,
  },

  computed: {},
  methods: {},
};
</script>
<style lang="less" scoped>
.adminmain {
}
</style>
