import request from "../utils/requst"

export const addOrderUrl = data =>{
    return request({
        method:'POST',
        url:'procedure/customer-work-order/add',
        data,
    })
}

export const SearchOrderUrl = params =>{
    return request({
        method:'GET',
        url:'procedure/customer-work-order/page',
        params,
    })
}

export const OneOrderUrl = params =>{
    return request({
        method:'GET',
        url:'procedure/customer-work-order/get',
        params,
    })
}

export const solveOrderUrl = data =>{
    return request({
        method:'POST',
        url:'procedure/customer-work-order/solve',
        data,
    })
}

export const completeOrderUrl = data =>{
    return request({
        method:'POST',
        url:'procedure/customer-work-order/complete',
        data,
    })
}

export const reopenFaeUrl = data =>{
    return request({
        method:'POST',
        url:'procedure/customer-work-order/technical_support_work_order/reopen',
        data,
    })
}

export const UnsolvedWorkOrderUrl = data =>{
    return request({
        method:'POST',
        url:'customer-work-order/home/summaryUnsolvedWorkOrder',
        data,
    })
}

export const WorkOrderMonthUrl = data =>{
    return request({
        method:'POST',
        url:'customer-work-order/home/summaryWorkOrderMonth',
        data,
    })
}

export const ExportExlUrl = params =>{
    return request({
        method:'GET',
        url:'procedure/customer-work-order/export',
        params,
        responseType: 'blob'
    })
}

export const OrderDelect = data =>{
    return request({
        method:'POST',
        url:'procedure/customer-work-order/del',
        data,
    })
}

export const OrderTransform = data =>{
    return request({
        method:'POST',
        url:'procedure/customer-work-order/transform',
        data,
    })
}






