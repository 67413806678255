<template>
  <div class="activation">
    <div class="title">FAE处理占比:</div>
    <div
      class="chart"
      :style="{ width: '100%', height: '300px' }"
      id="chart"
      ref="chart"
    />
  </div>
</template>

<script>
import * as echarts from "echarts";

import { getfaeOperatorDataUrl } from "@/api/admin";

export default {
  data() {
    return {
      echartsOptions:{
        grid: {
          x: 50,
          y: 25,
          x2: 30,
          y2: 55,
        },
        xAxis: {
          axisLabel: {
            show: true,
            textStyle: {
              fontSize: 15,
            },
          },
          type: "category",
          data: [
           
          ],
        },
        yAxis: {
          type: "value",
          axisLabel: {
            show: true,
            textStyle: {
              fontSize: 15,
            },
          },
        },
        series: [
          {
            label: {
              fontSize: 15,
              show: true,
              position: "top",
            },
            itemStyle: {
              normal: {
                //这里是重点
                color: function (params) {
                  //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                  var colorList = [
                    "#c23531",
                    "#2f4554",
                    "#61a0a8",
                    "#d48265",
                    "#91c7ae",
                    "#749f83",
                    "#ca8622",
                  ];
                  return colorList[params.dataIndex];
                },
              },
            },
            data: [],
            type: "bar",
            barWidth: 30, //柱图宽度
          },
        ],
      }
    };
  },

  mounted() {
     this.getdata();
    // this.initEcharts();
  },

  methods: {
    async getdata() {
      try {
        const res = await getfaeOperatorDataUrl();
        if (res.data.code === 200) {
          this.echartsOptions.xAxis.data = res.data.data.map(
            (item) => item.name
          );
          this.echartsOptions.series[0].data = res.data.data.map(
            (item) => item.value
          );
          this.initEcharts();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
      }
    },
    initEcharts() {
      const el = this.$refs["chart"];
      let myChart = echarts.init(el);
      myChart.setOption(this.echartsOptions);
    },
  },
};
</script>
<style lang="less" scoped>
.activation {
  .chart {
    width: 100%;
    flex: 1;
    position: relative;
    overflow: hidden;
  }
  .title {
    font-size: 15px;
    color: black;
    font-weight: bold;
  }
}
</style>
