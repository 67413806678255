<template>
  <div class="parentsdiv">
    <div class="erweima">
      <img src="@/assets/img/KKS-SMS.jpg" width="150" />
      微信扫描二维码， 关注公众号， 也可联系客服！
    </div>
    <div>
      <!-- <div>
        <img src="@/assets/img/jimilogo.png" width="150" />
      </div> -->
      <div class="tianxie">
        <div class="title">您好，欢迎来到几米客服反馈系统，请写下您的问题</div>
        <div class="dis">请注意：带*为必填项</div>
        <div class="main">
          <!-- //顶上加上jimi头，加上图片上传，加上有线无线 -->
          <el-form
            :model="ruleForm"
            :rules="rules"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="姓名" prop="name">
              <el-input class="short" v-model="ruleForm.name"></el-input>
            </el-form-item>
            <el-form-item label="电话号码" prop="phone">
              <el-input class="short" v-model="ruleForm.phone"></el-input>
              <el-button
                :disabled="disabled"
                class="leftgap"
                type="primary"
                @click="addorder()"
              >
                {{ btnTxt }}</el-button
              >
            </el-form-item>
            <el-form-item label="验证码" prop="code">
              <el-input class="short" v-model="ruleForm.code"></el-input>
            </el-form-item>
            <el-form-item label="IMEI号" prop="imei">
              <el-input class="short" v-model="ruleForm.imei"></el-input>
            </el-form-item>
            <el-form-item label="客诉类型" prop="type">
              <el-radio-group v-model="ruleForm.type">
                <el-radio :label="0">APP故障</el-radio>
                <el-radio :label="1">系统故障</el-radio>
                <el-radio :label="2">硬件维修</el-radio>
                <el-radio :label="3">技术支持</el-radio>
                <el-radio :label="4">使用咨询</el-radio>
                <el-radio :label="5">商务咨询</el-radio>
                <el-radio :label="6">投诉建议</el-radio>
                <el-radio :label="7">经销商问题</el-radio>
              </el-radio-group>
            </el-form-item>

            <el-form-item label="问题描述" prop="desc">
              <el-input
                class="dis"
                type="textarea"
                v-model="ruleForm.desc"
              ></el-input>
            </el-form-item>
            <el-form-item label="涉及机型" prop="pot">
              <el-select
                class="short"
                v-model="ruleForm.pot"
                filterable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in modeloption"
                  :key="item.value"
                  :label="item.value"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="产品类型">
              <el-radio-group v-model="ruleForm.resource">
                <el-radio label="无线设备"></el-radio>
                <el-radio label="有线设备"></el-radio>
                <el-radio label="学生卡"></el-radio>
                <el-radio label="视频安防"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="网络机制">
              <el-radio-group v-model="ruleForm.net">
                <el-radio label="2G"></el-radio>
                <el-radio label="3G"></el-radio>
                <el-radio label="4G"></el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="涉及平台">
              <el-select
                class="short"
                v-model="ruleForm.pingtai"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in pingtaioptions"
                  :key="item.value"
                  :label="item.value"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" @click="addskiporder()">确定</el-button>
              <el-button @click="resetForm('ruleForm')">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { skipaddUrl, getyzm } from "@/api/skip";
export default {
  data() {
    return {
      btnTxt: "获取验证码",
      disabled: false,
      time: 0,
      modeloption: [
        { value: "L341" },
        { value: "LL301" },
        { value: "EV26" },
        { value: "VG03" },
        { value: "GT370C" },
        { value: "LL01" },
        { value: "LL02(E)" },
        { value: "LL02(A)" },
        { value: "LL02(AG)" },
        { value: "VL01" },
        { value: "GT06L" },
        { value: "VL02" },
        { value: "VL03" },
        { value: "VL04(E)" },
        { value: "VL04(LA)" },
        { value: "VL04(A)" },
        { value: "VG04Q" },
        { value: "GT420D" },
        { value: "LG05" },
        { value: "GT260" },
        { value: "VG01U" },
        { value: "GT552" },
        { value: "VG02U" },
        { value: "GT833" },
        { value: "VW01" },
        { value: "GT550" },
        { value: "OB22" },
        { value: "GT810" },
        { value: "X3" },
        { value: "GT06N" },
        { value: "GT06E" },
        { value: "GT800" },
        { value: "GT08" },
        { value: "ET500" },
        { value: "EG02" },
        { value: "ET310S" },
        { value: "Wetrack2" },
        { value: "ET25" },
        { value: "Wetrack Lite" },
        { value: "ET20" },
        { value: "GV20" },
        { value: "HVT001" },
        { value: "CT10" },
        { value: "GT300F" },
        { value: "AT1" },
        { value: "GT320" },
        { value: "AT2" },
        { value: "GT370" },
        { value: "AT4" },
        { value: "GT841" },
        { value: "AT5" },
        { value: "GT370W" },
        { value: "AT6" },
        { value: "GT360" },
        { value: "Qbit" },
        { value: "GB110" },
        { value: "BL10" },
        { value: "LG300" },
        { value: "Qbit-M" },
        { value: "BL300" },
        { value: "LL306" },
        { value: "C450(A)" },
        { value: "JC450(A)" },
        { value: "C450(E)" },
        { value: "JC450(E)" },
        { value: "F1" },
        { value: "JC120" },
        { value: "JT40N" },
        { value: "JC500" },
        { value: "C28" },
        { value: "JC400" },
        { value: "C28D" },
        { value: "JC400D" },
        { value: "C28P" },
        { value: "JC400P" },
        { value: "C18" },
        { value: "JC200" },
        { value: "C8" },
        { value: "JC100" },
        { value: "B8H" },
        { value: "B8H" },
        { value: "V9" },
        { value: "B9" },
        { value: "JH18" },
        { value: "JH018" },
        { value: "PT01" },
        { value: "AM01" },
        { value: "JH016" },
        { value: "JH09" },
        { value: "B5" },
        { value: "E140" },
        { value: "E840" },
        { value: "EV40" },
        { value: "EV41S" },
        { value: "GK309S" },
           { value: "JC181" },
         { value: "JC261" },
           { value: "JC261P" },
        { value: "GT371" },
        { value: "GT740（无磁）" },
        { value: "LG01" },
        { value: "GT740（有磁）" },
        { value: "LG01(Back clip)" },
        { value: "L340" },
        { value: "L340YQ" },
        { value: "L342" },
        { value: "L343" },
        { value: "P240" },
        { value: "L740" },
        { value: "V541S" },
        { value: "S341" },
        { value: "S342" },
        { value: "S343" },
        { value: "S345" },
        { value: "V720" },
        { value: "米币" },
        { value: "Mi coin" },
        { value: "C170" },
        { value: "C450" },
        { value: "C150" },
        { value: "DMU-F2" },
        { value: "ET380" },
        { value: "EV25" },
        { value: "EV42" },
        { value: "EV48" },
        { value: "GK309E" },
        { value: "GT140C" },
        { value: "GT220" },
        { value: "JV200" },
        { value: "LL02" },
        { value: "L744" },
        { value: "L301" },
        { value: "LL302" },
        { value: "LL303" },
        { value: "V540" },
        { value: "V541" },
        { value: "VL03M" },
        { value: "VL04" },
        { value: "VL802" },
        { value: "S347" },
        { value: "L743" },
        { value: "LL702" },
        { value: "ET200" },
        { value: "ET320" },
        { value: "GK320S" },
        { value: "GT03F" },
        { value: "P340" },
          { value: "PL200" },
        { value: "JS100" },
        { value: "KS140" },
        { value: "PT12" },
        { value: "C28-A" },
        { value: "C28D-A" },
        { value: "C28P-A" },
        { value: "C7" },
        { value: "D02" },
        { value: "DC09" },
        { value: "DMU485" },
        { value: "DMU8955" },
        { value: "DNOEM" },
        { value: "E322" },
        { value: "E520" },
        { value: "EL100" },
        { value: "EL110" },
        { value: "EV41" },
        { value: "GT320E" },
        { value: "GT320J" },
        { value: "GT320S" },
        { value: "GT320Z" },
        { value: "GT770" },
        { value: "GV25" },
        { value: "JC450" },
        { value: "L121" },
        { value: "L741" },
        { value: "S240" },
        { value: "TBT100" },
        { value: "Y140" },
        { value: "GT710" },
        { value: "VL03E" },
        { value: "S431" },
      ],
      options: [
        {
          value: null,
          label: "不限",
        },
        {
          value: "0",
          label: "App故障",
        },
        {
          value: "1",
          label: "系统故障",
        },
        {
          value: "2",
          label: "硬件维修",
        },
        {
          value: "3",
          label: "技术支持",
        },
        {
          value: "4",
          label: "使用咨询",
        },
        {
          value: "5",
          label: "商务咨询",
        },
        {
          value: "6",
          label: "投诉建议",
        },
        {
          value: "7",
          label: "经销商问题",
        },
      ],
      pingtaioptions: [
        {
          value: "途强在线",
        },
        {
          value: "途强视讯",
        },

        {
          value: "风控平台",
        },

         {
          value: "SIM卡平台",
        },
        {
          value: "和丁丁",
        },

        {
          value: "爱车在线",
        },

        {
          value: "Tracksolid",
        },

        {
          value: "TracksolidPro",
        },

        {
          value: "Carmatrix",
        },
        {
          value: "法国定制平台",
        },
      ],
      ruleForm: {},
      rules: {
        name: [
          { required: true, message: "请输入您的名字", trigger: "blur" },
          { min: 2, max: 5, message: "长度在 2 到 5 个字符", trigger: "blur" },
        ],
        phone: [
          { required: true, message: "请输入您的电话号码", trigger: "blur" },
          { min: 11, max: 11, message: "长度在 11 个字符", trigger: "blur" },
        ],
        code: [
          { required: true, message: "请输入您的验证码", trigger: "blur" },
          { min: 6, max: 6, message: "长度在 6 个字符", trigger: "blur" },
        ],
        desc: [
          { required: true, message: "请输入您的问题描述", trigger: "blur" },
        ],
        imei: [
          { required: true, message: "请输入您的imei", trigger: "blur" },
          { min: 15, max: 15, message: "长度在 15 个字符", trigger: "blur" },
        ],
        type: [{ required: true, message: "请选类型", trigger: "change" }],
        times: [{ type: "number", message: "反馈次数必须为数字值" }],
      },
    };
  },

  mounted() {},

  created() {},

  methods: {
    async addorder() {
      try {
        const res = await getyzm({ phoneNumber: this.ruleForm.phone });
        console.log(res);
        if (res.data.code === 200) {
          this.$message({
            message: res.data.msg,
            type: "success",
          });
          this.time = 60; //设置倒计时的时间（单位：秒）
          this.disabled = true;
          this.timer(); //调用倒计时方法
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
      }
    },
    timer() {
      if (this.time > 0) {
        this.time--;
        console.log(this.time);
        this.btnTxt = this.time + "s后重新获取验证码";
        setTimeout(this.timer, 1000);
      } else {
        this.time = 0;
        this.btnTxt = "获取验证码";
        this.disabled = false;
      }
    },
    getliulanqiname() {
      const ua = window.navigator.userAgent;
      const isExplorer = (exp) => {
        return ua.indexOf(exp) > -1;
      };
      if (isExplorer("MSIE")) return "IE";
      else if (isExplorer("Firefox")) return "Firefox";
      else if (isExplorer("Chrome")) return "Chrome";
      else if (isExplorer("Opera")) return "Opera";
      else if (isExplorer("Safari")) return "Safari";
      else return "unknown";
    },
    async addskiporder() {
      let llqname = this.getliulanqiname();
      try {
        let remark =
          "imei:" +
          this.ruleForm.imei +
          " 涉及机型:" +
          this.ruleForm.pot +
          " 产品类型:" +
          this.ruleForm.resource +
          " 网络机制:" +
          this.ruleForm.net +
          " 涉及平台:" +
          this.ruleForm.pingtai;
        let form = {
          customerName: this.ruleForm.name,
          description: this.ruleForm.desc,
          phoneNumber: this.ruleForm.phone,
          customerComplaintType: this.ruleForm.type,
          remark: remark,
          code: this.ruleForm.code,
          sourceType: 0,
          sourceTypeName: llqname,
        };
        const res = await skipaddUrl(form);
        if (res.data.code === 200) {
          this.$message({
            message: "添加成功",
            type: "success",
          });
          this.ruleForm = {};
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.showchangepsw = false;
      }
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
<style lang="less" scoped>
.parentsdiv {
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: url("../../assets/img/loginbackground.jpeg");
  background-repeat: no-repeat;
  background-size: cover;

  .erweima {
    z-index: 99;
    // background: url("../../assets/img/KKS-SMS.jpg");
    // background-size: 100% 100%;
    position: absolute;
    left: 80%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 150px;
    height: 150px;
  }
  .tianxie {
    z-index: 99;
    // background: url("../../assets/img/KKS-SMS.jpg");
    // background-size: 100% 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 450px;
    height: 650px;
    border-radius: 25px;
    background: #ffffff;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: auto;
  }
}
&::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 6px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
&::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  background-color: #ededed;
  border-radius: 10px;
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
}
&::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 10px;
  background-color: #eee;
}
</style>
