<template>
  <!--
    el-menu-item 的 index 不能重复，确保唯一即可
    
   -->
   <!-- <div >
      <el-image
      style="width: 130px; height: 80px"
      :src="require('@/assets/img/jimilogo.png')"
      fit="contain"></el-image>
    
    </div> -->
  <div>
    <div class="maintitle">
      <div class="setimage"></div>
      <div class="text">
        <div class="mid">{{ $t("kfgdxt") }}</div>
      </div>
    </div>

    <el-menu
      :default-active="activeIndex"
      class="el-menu-vertical-demo"
      background-color="#002033"
      text-color="#fff"
      active-text-color="#ffd04b"
      router
      unique-opened
      :collapse="isCollapse"
    >
      <template v-for="(item, index) in menulist">
        <el-submenu
          :index="item.perms"
          :key="index"
          v-if="item.children && item.children.length > 0 && item.show"
        >
          <template slot="title">
            <i :class="item.icon && item.icon"></i>
            <span>{{ item.title }}</span>
          </template>
          <template v-for="(item2, index2) in item.children">
            <el-menu-item :key="index2" v-if="item2.show" :index="item2.index">
              <i :class="item2.icon && item2.icon"></i>
              <span slot="title">{{ item2.title }}</span>
            </el-menu-item>
          </template>
        </el-submenu>
        <el-menu-item v-else-if="item.show" :key="index" :index="item.index">
          <template slot="title">
            <i :class="item.icon && item.icon"></i>
            <span>{{ item.title }}</span>
          </template>
        </el-menu-item>
      </template>
      <!-- <el-menu-item index="/dashboard">
      <i class="el-icon-s-home"></i>
      <span slot="title">首页</span>
    </el-menu-item>
   
     <el-menu-item index="RoleManage">
      <i class="el-icon-s-custom"></i>
      <span slot="title">角色信息</span>
    </el-menu-item>


   <el-menu-item index="ReportManager">
      <i class="el-icon-date"></i>
      <span slot="title">客服工单管理</span>
    </el-menu-item>
     <el-menu-item index="FAEReportManager">
      <i class="el-icon-date"></i>
      <span slot="title">FAE工单管理</span>
    </el-menu-item> -->
    </el-menu>
  </div>
</template>

<script>
export default {
  name: "AppAside",
  components: {},
  props: ["isCollapse"],
  data() {
    return {
      activeIndex: "/",
      menulist: [],
    };
  },
  computed: {},
  watch: {},
  created() {
    this.activeIndex = this.getactiveIndex();
  },
  mounted() {
    this.menulist = JSON.parse(window.localStorage.getItem("menulist"));
  },
  methods: {
    getactiveIndex() {
      let Index = window.location.href.split("/")[4];
      if (Index === "") {
        Index = "/";
      }
      return Index;
    },
  },
};
</script>

<style scoped lang="less">
.nav-menu {
  .iconfont {
    margin-right: 10px;
    padding-left: 5px;
  }
}
.maintitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items:center ;
  .setimage {
    padding: 3px;
    background: url("../../../assets/img/jimilogo.png");
    background-size: 100% 100%;
    height: 14px;
    width: 72px;
  }
  .text {
    .mid {
      margin: 8px;
      color: #ffffff;
      font-size: 17px;
      text-align: center;
    }
  }
}
.el-menu-vertical-demo:not(.el-menu--collapse) {

  height: 100%;
}
.elimage {
  padding: 10px;
}
.el-scrollbar__thumb {
  display: none;
}
</style>
