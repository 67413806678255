<template>
  <div class="maindiv parents">
    <div class="leftwater"></div>
    <div class="loginbg">
      <div :class="changetitle()">{{ $t("kfgdfwxt") }}</div>

      <div class="topgap">
        <el-input
          class="topgap"
          prefix-icon="el-icon-s-custom"
          placeholder="请输入账号"
          v-model="account"
        ></el-input>
        <el-input
          show-password
          class="topgap"
          prefix-icon="el-icon-unlock"
          placeholder="请输入密码"
          v-model="password"
        ></el-input>
        <el-button
          class="login-btn"
          type="primary"
          :loading="loginLoading"
          @click="onLogin"
          >{{ $t("denglu") }}</el-button
        >
        <el-switch
          v-model="Language"
          active-text="English"
          inactive-text="中文"
          active-value="en"
          inactive-value="zh-CN"
          @change="LanguageChange"
        >
        </el-switch>
      </div>
    </div>
    <div class="beian">
      <div class="big">
        Copyright 2022 版权所有几米物联粤ICP备15058697号粤公网安备
        44030602005836
      </div>
    </div>
  </div>
</template>

<script>
import { login, getUserInfoUrl } from "@/api/admin";

export default {
  data() {
    return {
      loginLoading: false,
      account: "",
      password: "",
      Language: null,
      admin: false,
      options: [],
      value: "",
      cspassword: "",
      menulist: [
        {
          perms: "system",
          index: "/dashboard",
          icon: "el-icon-s-home",
          title: "首页",
          show: true,
        },
        {
          perms: "work_order",
          icon: "el-icon-s-data",
          title: "统计管理",
          show: true,
          children: [
            {
              perms: "ec-serve-client-work-order:manage",
              index: "/ESOrderReportManager",
              icon: "el-icon-menu",
              title: "电商工单",
              show: true,
            },
            {
              perms: "customer-work-order:manage",
              index: "/CustomerReportManager",
              icon: "el-icon-menu",
              title: "客服工单",
              show: true,
            },
            {
              perms: "fae-work-order:manage",
              index: "/FAEReportManager",
              icon: "el-icon-menu",
              title: "FAE工单",
              show: true,
            },
            {
              perms: "development-work-order:manage",
              index: "/TechReportManager",
              icon: "el-icon-menu",
              title: "研发工单",
              show: true,
            },
          ],
        },
        {
          perms: "work_order_manage",
          icon: "el-icon-s-operation",
          title: this.$t("gdgl"),
          show: true,
          children: [
            {
              perms: "customer-work-order",
              index: "/CustomerOrderset",
              icon: "el-icon-phone-outline",
              title: "国内客服",
              show: true,
            },
            {
              perms: "ec-serve-client-work-order",
              index: "/ESOrderSet",
              icon: "el-icon-sell",
              title: "海外电商",
              show: true,
            },

            {
              perms: "business-customer-work-order",
              index: "/InternationalOrder",
              icon: "el-icon-s-promotion",
              title: "海外客户",
              show: true,
            },

            {
              perms: "client-customer-work-order",
              index: "/clientOrder",
              icon: "el-icon-user",
              title: "国内客户",
              show: true,
            },

            
            {
              perms: "website-work-order",
              index: "/websiteOrder",
              icon: "el-icon-help",
              title: "官网工单",
              show: true,
            },

            {
              perms: "website-ec-serve-work-order",
              index: "/OwtoecOrder",
              icon: "el-icon-goods",
              title: "官网转电商",
              show: true,
            },


            {
              perms: "technical-support-work-order",
              index: "/FAEOrderset",
              icon: "el-icon-s-custom",
              title: this.$t("faeAndyw"),
              show: true,
            },

            {
              perms: "development-work-order",
              index: "/TechOrderSet",
              icon: "el-icon-cpu",
              title: "研发工站",
              show: true,
            },
          ],
        },
        {
          perms: "quality_management",
          icon: "el-icon-message-solid",
          title: "品质管理",
          show: true,
          children: [
            {
              perms: "customer-complaint-work-order",
              index: "/QualityOrderSet",
              icon: "el-icon-s-comment",
              title: "客诉工单",
              show: true,
            },
            {
              perms: "quality-report",
              index: "/QuiltyReport",
              icon: "el-icon-tickets",
              title: "品质报告",
              show: true,
            },
          ],
        },
        {
          perms: "opinion-work-order",
          icon: "el-icon-info",
          title: "意见管理",
          show: true,
          children: [
            {
              perms: "opinion-work-order:manage",
              index: "/OpinionManage",
              icon: "el-icon-s-comment",
              title: "客户意见单",
              show: true,
            },
          ],
        },

        {
          perms: "assessment_management",
          icon: "el-icon-chat-dot-round",
          title: "评价管理",
          show: true,
          children: [
            {
              perms: "customer-work-order-assess",
              index: "/CustomerAssess",
              icon: "el-icon-s-comment",
              title: "客户评价",
              show: true,
            },
          ],
        },

        {
          perms: "base-information",
          icon: "el-icon-setting",
          title: "系统管理 ",
          show: true,
          children: [
            {
              perms: "dept:manage",
              index: "/department",
              icon: "el-icon-more",
              title: "部门",
              show: true,
            },
            {
              perms: "post:manage",
              index: "/position",
              icon: "el-icon-s-custom",
              title: "职位",
              show: true,
            },
            {
              perms: "role:manage",
              index: "/role",
              icon: "el-icon-user",
              title: "角色",
              show: true,
            },
            {
              perms: "user:manage",
              index: "/user-info",
              icon: "el-icon-user-solid",
              title: "用户",
              show: true,
            },
            {
              perms: "tobcustomer:manage",
              index: "/tobcustomer",
              icon: "el-icon-s-check",
              title: "海外客户",
              show: true,
            },
          ],
        },
      ],
      limitdata: [],
    };
  },

  mounted() {
    this.Language = this.$i18n.locale;
  },

  created() {},

  methods: {
    changetitle() {
      if (this.Language === "zh-CN") {
        return "title";
      } else {
        return "titleEng";
      }
    },
    LanguageChange(value) {
      this.$i18n.locale = value;
      localStorage.setItem("change-language", value);
      location.reload();
    },
    async onLogin() {
      let data = {
        account: this.account,
        password: this.password,
      };
      try {
        const res = await login(data);
        if (res.data.code === 200) {
          window.localStorage.setItem(
            "usertoken",
            JSON.stringify(res.data.data)
          );
          this.getUserInfo();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      }
    },
    async getUserInfo() {
      try {
        const res = await getUserInfoUrl({ Language: this.Language });
        if (res.data.code === 200) {
          window.localStorage.setItem(
            "userdata",
            JSON.stringify(res.data.data)
          );
          this.limitdata = res.data.data.permInfos;
          this.getlimit();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      }
    },
    getlimit() {
      for (let i = 0; i < this.menulist.length; i++) {
        for (let l = 0; l < this.limitdata.length; l++) {
          if (this.menulist[i].perms == this.limitdata[l].perms) {
            break;
          } else if (l == this.limitdata.length - 1) {
            this.menulist[i].show = false;
            break;
          }
        }

        if (this.menulist[i].children != null) {
          for (let j = 0; j < this.menulist[i].children.length; j++) {
            for (let k = 0; k < this.limitdata.length; k++) {
              if (
                this.menulist[i].children[j].perms == this.limitdata[k].perms
              ) {
                break;
              } else if (k == this.limitdata.length - 1) {
                this.menulist[i].children[j].show = false;
                break;
              }
            }
          }
        }
      }

      window.localStorage.setItem("menulist", JSON.stringify(this.menulist));
      this.$router.push({
        name: "adminMain",
      });
    },
  },
};
</script>
<style lang="less" scoped>
.parents {
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: relative;
  background: url("../../assets/img/loginbackground.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  //opacity: 0.5;
  //background-size：100% 100%;
  .beian {
    z-index: 999;
    position: absolute;
    top: 100%;
    transform: translate(100%, -100%);
    .big {
      font-weight: bolder;
      font-size: 13px;
    }
  }
  .loginbg {
    z-index: 99;
    background: url("../../assets/img/loginForm.png");
    background-size: 100% 100%;
    position: absolute;
    left: 70%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 300px;
    height: 520px;
    height: 52 0px;
    padding: 10px 50px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      margin-top: 20px;
      margin-top: 120px;
      font-size: 32px;
    }
    .titleEng {
      margin-top: 20px;
      margin-top: 120px;
      font-size: 20px;
    }

    .login-btn {
      margin-top: 20px;
      width: 100%;
    }
  }
  .leftwater {
    z-index: 1;
    width: 100%;
    height: 100%;
    background: url("../../assets/img/water.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
  }
}
.topgap {
  margin-top: 10px;
}
.el-select {
  width: 100%;
}
</style>
