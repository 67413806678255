<template>
  <div>
    <el-descriptions title="查看详情" direction="vertical" :column="1" border>
      <el-descriptions-item label="当前进度">
        <el-steps :active="processdata" finish-status="success">
          <el-step title="问题情况反馈"></el-step>
          <el-step title="客服工单记录"></el-step>
          <el-step title="工单派发"></el-step>
          <el-step title="工单处理"></el-step>
          <el-step title="工单完成 "></el-step>
          <el-step title="客服工单结案"></el-step>
          <el-step title="客户评价"></el-step>
        </el-steps>
      </el-descriptions-item>
      <el-descriptions-item label="问题描述">{{
        dataildata.description
      }}</el-descriptions-item>
      <el-descriptions-item label="问题原因">{{
        dataildata.reason
      }}</el-descriptions-item>
      <el-descriptions-item label="解决方案">{{
        dataildata.solution
      }}</el-descriptions-item>
      <el-descriptions-item label="解决模式" v-if="dataildata.mode">
        {{ modelStatus[dataildata.mode].text }}
      </el-descriptions-item>

      <el-descriptions-item label="图片附件">
        <div class="demo-image__lazy">
          <el-image
            v-for="url in dataildata.fileInfos"
            :key="url.indexOf"
            :src="url.url"
            lazy
          ></el-image></div
      ></el-descriptions-item>

      <el-descriptions-item label="客服备注">{{
        dataildata.remark
      }}</el-descriptions-item>
      <!-- <el-descriptions-item label="客户评价">{{
        dataildata.feedback
      }}</el-descriptions-item> -->
    </el-descriptions>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modelStatus: [
        { status: 0, text: "已解决客诉，并从根源解决", type: "info" }, // 1
        { status: 1, text: "已解决客诉，未从解决根源问题", type: "info" }, //
        { status: 2, text: "已解决客诉，未能找到根源问题", type: "info" }, //
      ],
    };
  },
  props: {
    dataildata: {},
  },
  computed: {
    // 计算属性的 getter
   processdata: function () {
     
      if (this.dataildata.process === 0) {
        return 3
      } else if (this.dataildata.process === 1) {
        return 5;
      } else if (this.dataildata.process === 2) {
        return 6;
      }
    },
  },

  mounted() {},
  created() {},
  methods: {},
};
</script>
<style lang='less' scoped>
.detile {
  padding: 20px;
  width: 100%;
  border-style: solid;
  border-width: 1px;
}
/deep/.el-descriptions-item__label {
    background: #77d5f4;
    font-weight: bolder;
    color: black;
}


</style>