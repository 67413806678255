import request from "../utils/requst"

export const skipaddUrl = data =>{
    return request({
        method:'POST',
        url:'procedure/person-work-order/open/add',
        data,
    })
}
export const getyzm = data =>{
    return request({
        method:'POST',
        url:'procedure/person-work-order/open/captcha/get',
        data,
    })
}

