<template>
  <div class="article-container">
    <el-card class="filter-card">
      <!-- 数据筛选表单 -->
      <el-form ref="form" label-width="80px" size="mini">
        <div class="flexparents">
          <!-- <el-radio-group v-model="form.stations" @change="loadArticles(1)">
            <el-radio-button label="5,2,3">电商</el-radio-button>
            <el-radio-button label="2,3">FAE</el-radio-button>
            <el-radio-button label="3">研发</el-radio-button>
          </el-radio-group> -->
          <el-cascader
            class="leftgap"
            v-show="form.stations === '3'"
            v-model="selectdept"
            type="cascade"
            :options="treedata"
            :props="{ checkStrictly: true, label: 'name', value: 'deptId' }"
            placeholder="请选择部门"
            clearable
            @change="handleSelectDept"
          />
        </div>
        <div class="flexparents">
          开始日期:
          <el-date-picker
            value-format="yyyy-MM-dd 00:00:00"
            v-model="form.createTimeAfter"
            type="date"
            placeholder="选择开始日期"
          >
          </el-date-picker>
          结束日期:
          <el-date-picker
            value-format="yyyy-MM-dd 23:59:59"
            v-model="form.creatTimeBefore"
            type="date"
            placeholder="选择结束日期"
          >
          </el-date-picker>

          <el-button
            class="leftgap"
            type="primary"
            :disabled="loading"
            @click="cleardata()"
            >清空</el-button
          >
          <el-button type="primary" :disabled="loading" @click="loadArticles(1)"
            >查询</el-button
          >

          <el-button type="success" :disabled="loading" @click="exportexcel()"
            >导出</el-button
          >
          <el-button
            type="info"
            :disabled="loading"
            @click="ifgaoji = !ifgaoji"
            >{{ ifgaoji ? "收起" : "高级查询" }}</el-button
          >
          <div v-if="ifgaoji" class="flexparents zdhh">
            <div>
              单号:
              <el-input
                class="input rightgap"
                v-model="form.workOrderNo"
                placeholder="请输入单号"
              ></el-input>
            </div>
            <div>
              国家:
              <el-input
                class="input rightgap"
                v-model="form.country"
                placeholder="请输入国家"
              ></el-input>
            </div>
            <div>
              购买渠道:
              <el-input
                class="input rightgap"
                v-model="form.channel"
                placeholder="请输入国家"
              ></el-input>
            </div>
            <div>
              描述:
              <el-input
                class="input rightgap"
                v-model="form.description"
                placeholder="请输入描述"
              ></el-input>
            </div>
            <div>
              邮箱:
              <el-input
                class="input rightgap"
                v-model="form.email"
                placeholder="请输入email"
              ></el-input>
            </div>
            <div>
              imei:
              <el-input
                class="input rightgap"
                v-model="form.imei"
                placeholder="请输入imei"
              ></el-input>
            </div>
            <div>
              当前站点:
              <el-select
                class="flexparents rightgap"
                v-model="form.stations"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in stationsoption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
            <div>
              是否转出:
              <el-select
                class="flexparents rightgap"
                v-model="form.transformFlag"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in transformFlagoption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>

            <div>
              问题类型:
              <el-select
                class="flexparents rightgap"
                v-model="form.type"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in typeoption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
      </el-form>
      <!-- /数据筛选表单 -->
    </el-card>

    <el-card class="box-card">
      <div slot="header" class="clearfix">
        根据筛选条件共查询到 {{ totalCount }} 条结果：
      </div>
      <el-table
        :data="articles"
        stripe
        :height="tableHeight"
        class="list-table"
        size="mini"
        v-loading="loading"
      >
        <el-table-column prop="orderNo" label="工单号"> </el-table-column>
        <el-table-column label="问题状态">
          <template slot-scope="scope">
            <el-tag :type="processStatus[scope.row.process].type">{{
              processStatus[scope.row.process].text
            }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="email" label="邮箱"> </el-table-column>
        <el-table-column prop="channel" label="购买渠道"> </el-table-column>
        <el-table-column prop="country" label="国家"> </el-table-column>
        <el-table-column prop="imei" label="IMEI"> </el-table-column>
        <el-table-column prop="platform" label="平台/APP"> </el-table-column>
        <el-table-column prop="description" width="350" label="描述">
        </el-table-column>
        <el-table-column label="使用场景">
          <!-- 如果需要在自定义列模板中获取当前遍历项数据，那么就在 template 上声明 slot-scope="scope" -->
          <template slot-scope="scope">
            <el-tag
              v-if="scope.row.usageScenario"
              :type="usageScenarioStatus[scope.row.usageScenario].type"
              >{{ usageScenarioStatus[scope.row.usageScenario].text }}</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="当前站点">
          <!-- 如果需要在自定义列模板中获取当前遍历项数据，那么就在 template 上声明 slot-scope="scope" -->
          <template slot-scope="scope">
            <el-tag :type="progressStatus[scope.row.station].type">{{
              progressStatus[scope.row.station].text
            }}</el-tag>
          </template>
        </el-table-column>

        <el-table-column prop="createdTime" label="创建时间"> </el-table-column>
        <el-table-column prop="completedByName" label="完成人">
        </el-table-column>
        <el-table-column prop="faeOperatorName" label="FAE操作员">
        </el-table-column>
        <el-table-column width="160" label="操作" fixed="right">
          <template slot-scope="scope">
            <div>
              <el-tooltip
                content="查看详情"
                placement="top"
                :open-delay="0"
                :enterable="false"
              >
                <el-button
                  size="mini"
                  @click="getdetaile(scope.row.workOrderId)"
                  circle
                  icon="el-icon-tickets"
                  type="primary"
                ></el-button>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        layout="sizes,prev, pager, next"
        background
        :total="totalCount"
        :page-sizes="[10, 20, 50, 100]"
        @size-change="handleSizeChange"
        :page-size="form.size"
        :disabled="loading"
        :current-page.sync="form.page"
        @current-change="onCurrentChange"
      />
      <!-- /列表分页 -->
    </el-card>

    <el-dialog
      title="工单详情:"
      modal-append-to-body
      :visible.sync="showOrder"
      width="1100px"
    >
      <el-descriptions
        class="desbasebody"
        :column="3"
        style="margin-bottom: 2rem"
      >
        <el-descriptions-item label="订单号">{{
          esservedatiledata.orderNo
        }}</el-descriptions-item>
        <el-descriptions-item label="问题类型">{{
          esservedatiledata.type
        }}</el-descriptions-item>
        <el-descriptions-item label="跟进人">{{
          esservedatiledata.followPersonName
        }}</el-descriptions-item>
        <el-descriptions-item label="IMEI">{{
          esservedatiledata.imei
        }}</el-descriptions-item>
        <el-descriptions-item label="设备状态">{{
          esservedatiledata.deviceStatus
        }}</el-descriptions-item>
        <el-descriptions-item label="设备LED灯状态">{{
          esservedatiledata.ledStatus
        }}</el-descriptions-item>
        <el-descriptions-item label="型号">{{
          esservedatiledata.deviceModel
        }}</el-descriptions-item>
        <el-descriptions-item label="平台/APP">{{
          esservedatiledata.platform
        }}</el-descriptions-item>
        <el-descriptions-item label="SIM卡运营商">{{
          esservedatiledata.simCardOperator
        }}</el-descriptions-item>
        <el-descriptions-item label="ICCID">{{
          esservedatiledata.iccid
        }}</el-descriptions-item>
        <el-descriptions-item label="使用场景">{{
          esservedatiledata.usageScenario
        }}</el-descriptions-item>
        <el-descriptions-item label="安装车型号">{{
          esservedatiledata.installedVehicleModel
        }}</el-descriptions-item>
        <el-descriptions-item label="Email">{{
          esservedatiledata.email
        }}</el-descriptions-item>
        <el-descriptions-item label="其他联系方式">{{
          esservedatiledata.otherContactInfo
        }}</el-descriptions-item>
        <el-descriptions-item label="FAE操作员">{{
          FAEdatile.faeOperatorName
        }}</el-descriptions-item>
        <el-descriptions-item label="购买渠道">{{
          esservedatiledata.channel
        }}</el-descriptions-item>
        <el-descriptions-item label="国家">{{
          esservedatiledata.country
        }}</el-descriptions-item>
        <el-descriptions-item label="购买订单号">{{
          esservedatiledata.orderNum
        }}</el-descriptions-item>
        <el-descriptions-item label="创建时间">{{
          esservedatiledata.createdTime
        }}</el-descriptions-item>
        <el-descriptions-item label="完成时间">{{
          esservedatiledata.completedTime
        }}</el-descriptions-item>
      </el-descriptions>

      <div class="desbody" v-if="this.esservedatiledata.orderNo">
        <el-descriptions direction="vertical" :column="3">
          <el-descriptions-item label="问题描述" :span="3">
            <p style="white-space: pre-line">
              {{ esservedatiledata.description }}
            </p>
          </el-descriptions-item>
          <el-descriptions-item :span="4" label="问题进度">{{
            esservedatiledata.solution
          }}</el-descriptions-item>
          <el-descriptions-item :span="4" label="备注">{{
            esservedatiledata.remark
          }}</el-descriptions-item>

          <el-descriptions-item label="图片" :span="16">
            <el-image
              style="width: 100px; height: 100px; margin-right: 10px"
              v-for="{ url } in esservedatiledata.image"
              :key="url"
              :src="url"
              :previewSrcList="ESpreviewList"
            />
          </el-descriptions-item>
          <el-descriptions-item label="附件" :span="16">
            <p v-for="{ url, path } in esservedatiledata.attachment" :key="url">
              <a :href="url" target="_blank">{{ path }}</a>
            </p>
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="showOrder = false">关闭</el-button>
      </span> -->
    </el-dialog>
  </div>
</template>
<script>
import { getESpageUrl, getOneESorderUrl, ExportExlUrl } from "@/api/esserve";
import {
  progressStatus
} from "@/options/index";
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      showAssignFaeOperator: false,
      FAElist: [],
      previewList: [],
      tableHeight: window.innerHeight - 320,
      loading: true, // 表单数据加载中 loading
      articles: [], // 文章数据列表
      totalCount: 0, // 总数据条数
      ifgaoji: false,

      progressStatus,

      form: {
        ownerType: 5,
        createdByStations: 5,
        creatTimeBefore: null,
        createTimeAfter: null,
        size: 20, // 每页大小
        page: 1, // 当前页码

        workOrderNo: null,
        description: null,
        email: null,
        imei: null,
        country: null,

        stations: null,
        type: null,
        usageScenario: null,
        channel: null,
        transformFlag: null,
      },

      stationsoption: [
        {
          value: null,
          label: "全部",
        },
        {
          value: 5,
          label: "电商客户服务",
        },
        {
          value: 2,
          label: "FAE",
        },
        {
          value: 3,
          label: "研发",
        },
      ],
      transformFlagoption: [
        {
          value: null,
          label: "全部",
        },
        {
          value: "1",
          label: "是",
        },
        {
          value: "0",
          label: "否",
        },
      ],
      typeoption: [
        {
          value: null,
          label: "全部",
        },
        {
          value: "0",
          label: "Device Inactive",
        },
        {
          value: "1",
          label: "Device Offline",
        },
        {
          value: "2",
          label: "IMEI Not Existed",
        },
        {
          value: "3",
          label: "I already paid, still not working",
        },
        {
          value: "4",
          label: "Wrong location",
        },
        {
          value: "5",
          label: "About Alert",
        },
        {
          value: "6",
          label: "About Subscription",
        },
        {
          value: "7",
          label: "About Battery / Charging",
        },
      ],
      usageScenariooption: [
        {
          value: null,
          label: "全部",
        },
        {
          value: "0",
          label: "Vehicle",
        },
        {
          value: "1",
          label: "Human",
        },
        {
          value: "2",
          label: "Asset",
        },
        {
          value: "3",
          label: "Other",
        },
      ],
      processStatus: [
        { status: 0, text: "开启", type: "primary" },
        { status: 1, text: "完成", type: "success" },
        { status: 2, text: "待评价", type: "info" },
        { status: 3, text: "已转出", type: "warning" },
        { status: 4, text: "已完成", type: "warning" },
        { status: 5, text: "待完成", type: "info" },
      ],

      usageScenarioStatus: [
        { status: 0, text: "Vehicle", type: "info" },
        { status: 1, text: "Human", type: "info" },
        { status: 2, text: "Asset", type: "info" },
        { status: 3, text: "Other", type: "info" },
      ],
   
      esservedatiledata: {},
      FAEdatile: {},
      showOrder: false,
    };
  },
  computed: {},
  watch: {},
  created() {
    window.addEventListener("resize", this.getTableHeight);
  },
  destroyed() {
    window.removeEventListener("resize", this.getTableHeight);
  },
  mounted() {
    this.loadArticles(1);
  },
  methods: {
    async getdetaile(id) {
      this.esservedatiledata = {};
      try {
        const res = await getOneESorderUrl({
          workOrderId: id,
        });
        if (res.data.code === 200) {
          this.FAEdatile = res.data.data?.technicalSupportWorkOrderVo || {};
          this.esservedatiledata = {
            ...res.data.data.ecServeClientWorkOrderVo,
            attachment: (
              res.data.data.ecServeClientWorkOrderVo.fileInfos || []
            ).map(({ path, url }) => ({
              path,
              url,
            })),
            image: (
              res.data.data.ecServeClientWorkOrderVo.imageInfos || []
            ).map(({ path, url }) => ({
              path,
              url,
            })),
          };
          // 预览图片列表
          this.previewList = (
            res.data.data.ecServeClientWorkOrderVo.imageInfos || []
          ).map(({ url }) => url);

          if (this.esservedatiledata.type == 0) {
            this.esservedatiledata.type = "Unable to activate";
          } else if (this.esservedatiledata.type == 1) {
            this.esservedatiledata.type = "Offline";
          } else if (this.esservedatiledata.type == 2) {
            this.esservedatiledata.type = "APP";
          } else if (this.esservedatiledata.type == 3) {
            this.esservedatiledata.type = "Locate";
          } else if (this.esservedatiledata.type == 4) {
            this.esservedatiledata.type = "SIM card";
          } else if (this.esservedatiledata.type == 5) {
            this.esservedatiledata.type = "Package";
          } else if (this.esservedatiledata.type == 6) {
            this.esservedatiledata.type = "other";
          }

          if (this.esservedatiledata.usageScenario == 0) {
            this.esservedatiledata.usageScenario = "Vehicle";
          } else if (this.esservedatiledata.usageScenario == 1) {
            this.esservedatiledata.usageScenario = "Human";
          } else if (this.esservedatiledata.usageScenario == 2) {
            this.esservedatiledata.usageScenario = "Asset";
          } else if (this.esservedatiledata.usageScenario == 3) {
            this.esservedatiledata.usageScenario = "Other";
          }
          this.showOrder = true;
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
      }
    },
    getTableHeight() {
      this.tableHeight = window.innerHeight - 320;
    },
    async exportexcel() {
      try {
        let res;
        res = await ExportExlUrl(this.form);
        if (res.status === 200) {
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
          });
          const objectUrl = URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.setAttribute("href", objectUrl);
          a.setAttribute(
            "download",
            decodeURIComponent(
              /filename=(.*)/.exec(res.headers["content-disposition"])[1]
            )
          );
          a.click();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    },
    handleSizeChange(val) {
      this.form.size = val;
      this.loadArticles();
    },
    onCurrentChange(page) {
      this.loadArticles(page);
    },
    async loadArticles(page = 1) {
      this.loading = true;
      try {
        const res = await getESpageUrl(this.form);
        if (res.data.code === 200) {
          this.articles = res.data.data.record;
          this.totalCount = res.data.data.total;
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
        this.loading = false;
      }

      // });
    },
  },
};
</script>

<style scoped lang="less">
.header {
  float: right;
}

.article-container {
  height: 100%;
}

.list-table {
}

.article-cover {
  width: 60px;
  background-size: cover;
}
.input {
  width: 200px;
  margin-right: 10px;
}
.filter-card {
  margin-bottom: 5px;
}
.flexparents {
  margin-bottom: 5px;
}
.el-select {
  width: 150px;
}
.leftgap {
  margin-left: 20px;
}
.rightgap {
  margin-right: 20px;
}

.zdhh {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.floter {
  display: flex;
  justify-content: space-between;
}
/deep/.el-table .warning-row {
  background: oldlace;
  font-weight: bold;
  font-size: 12px;
}
/deep/.el-table .complate-row {
  background: #ffffff;
 
}
/deep/.el-table .white-row {
  background: #ffffff;
  font-weight: bold;
  font-size: 12px;
}

.destitle {
  padding-left: 18px;
  margin-top: 12px;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 18px;
  color: rgb(0, 0, 0);
}

/deep/.el-descriptions-item__label {
  font-weight: bold !important;
  color: rgb(0, 0, 0);
}

.desbasebody {
  padding: 15px;
  border: 1px solid #0a0a0a34;
  border-radius: 25px;
  background-color: #ecf4fd;
  /deep/.el-descriptions__table {
    background-color: #ecf4fd;
  }
}

.desbody {
  padding: 15px;
  border: 1px solid #0a0a0a34;
  border-radius: 25px;
}
</style>
