<template>
  <div class="parents">
    <el-card class="box-card">
      <div>
        <el-form ref="form" :model="form" label-width="180px" >
          <el-form-item label="当前产品："> 开发者工单 </el-form-item>
          <el-form-item label="当前问题：">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item class="firstred" label="*问题描述：">
            <el-input type="textarea" v-model="form.desc"></el-input>
          </el-form-item>
          <el-form-item label="节点信息：">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="产品规格：">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="当前问题：">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">立即创建</el-button>
            <el-button>取消</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: "",
        region: "",
        date1: "",
        date2: "",
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
    };
  },
  methods: {
    onSubmit() {
      console.log("submit!");
    },
  },
};
</script>

<style scoped lang="less">
.parents {
  padding: 20px;
  .box-card {
    width: 100%;
    height: 100%;
  }
}
.firstred{

}
</style>
