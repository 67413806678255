<template>
  <div>
    <div class="steps-container">
      <el-steps :active="processdata" finish-status="success">
        <el-step title="问题情况反馈"></el-step>
        <el-step title="客服工单记录"></el-step>
        <el-step title="工单派发"></el-step>
        <el-step title="工单处理"></el-step>
        <el-step title="工单完成 "></el-step>
        <el-step title="客服工单结案"></el-step>
        <el-step title="客户评价"></el-step>
      </el-steps>
    </div>

    <el-descriptions border :column="2">
      <el-descriptions-item label="客服单号">{{
        dataildata.orderNo
      }}</el-descriptions-item>
      <el-descriptions-item label="创建人">{{
        dataildata.createdName
      }}</el-descriptions-item>
      <el-descriptions-item label="客户姓名">{{
        dataildata.customerName
      }}</el-descriptions-item>
      <el-descriptions-item label="创建时间">{{
        dataildata.createdTime
      }}</el-descriptions-item>
      <el-descriptions-item label="客户手机号">{{
        dataildata.phoneNumber
      }}</el-descriptions-item>
      <el-descriptions-item label="客诉标题">{{
        dataildata.title
      }}</el-descriptions-item>
      <el-descriptions-item label="问题原因" :span="2">{{
        dataildata.reason
      }}</el-descriptions-item>
      <el-descriptions-item label="问题描述" :span="2">
        {{ dataildata.description }}
      </el-descriptions-item>
      <el-descriptions-item label="客服备注" :span="4">{{
        dataildata.remark
      }}</el-descriptions-item>
      <el-descriptions-item label="图片" :span="12">
        <el-image
          v-for="url in dataildata.fileInfos"
          :key="url.indexOf"
          :src="url.url"
          lazy
        ></el-image>
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modelStatus: [
        { status: 0, text: "已解决客诉，并从根源解决", type: "info" }, // 1
        { status: 1, text: "已解决客诉，未从解决根源问题", type: "info" }, //
        { status: 2, text: "已解决客诉，未能找到根源问题", type: "info" }, //
      ],
    };
  },
  props: {
    dataildata: {},
  },
  computed: {
    // 计算属性的 getter
    processdata: function () {
      if (this.dataildata.process === 0) {
        return 3;
      } else if (this.dataildata.process === 1) {
        return 5;
      } else if (this.dataildata.process === 2) {
        return 6;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.steps-container {
  margin: 0 8px 24px;
}
</style>
