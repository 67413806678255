<template>
  <div class="activation">
    <div class="title">渠道类型:</div>
    <div
      class="chart"
      :style="{ width: '100%', height: '300px' }"
      id="chart"
      ref="chart"
    />
  </div>
</template>

<script>
import * as echarts from "echarts";
import { getchannelsDataUrl } from "@/api/admin";
export default {
  data() {
    return {
      echartsOptions: {
        title: {},
        tooltip: {
          trigger: "item",
        },
        legend: {},
        series: [
          {
            label: {
              show: false,
              position: "center",
            },
            center: ["50%", "50%"],
            type: "pie",
            radius: "85%",
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },
    };
  },

  mounted() {
    this.getdata();
  },

  methods: {
    async getdata() {
      try {
        const res = await getchannelsDataUrl();
        if (res.data.code === 200) {
          this.echartsOptions.series[0].data = res.data.data;
          this.initEcharts();
        } else {
          this.$message({
            message: res.data.msg,
            type: "error",
          });
        }
      } catch (e) {
        this.$message({
          message: e,
          type: "error",
        });
      } finally {
      }
    },

    initEcharts() {
      const el = this.$refs["chart"];
      let myChart = echarts.init(el);
      myChart.setOption(this.echartsOptions);
    },
  },
};
</script>
<style lang="less" scoped>
.activation {
  .chart {
    width: 100%;
    flex: 1;
    position: relative;
    overflow: hidden;
  }
  .title {
    font-size: 15px;
    color: black;
    font-weight: bold;
  }
}
</style>
