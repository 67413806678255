<template>
  <div>
    <el-descriptions
      title="客服工单详情"
      direction="vertical"
      :column="4"
      border
    >
      <el-descriptions-item label="工单号">{{
        dataildata.orderNo
      }}</el-descriptions-item>
      <el-descriptions-item label="创建人">{{
        dataildata.createdName
      }}</el-descriptions-item>
      <el-descriptions-item label="大区">{{
        dataildata.area
      }}</el-descriptions-item>
      <el-descriptions-item label="客户(国内)">{{
        dataildata.customerName
      }}</el-descriptions-item>
      <el-descriptions-item label="销售">{{
        dataildata.salesman
      }}</el-descriptions-item>
      <el-descriptions-item label="客诉类型">{{
        dataildata.type
      }}</el-descriptions-item>
      <el-descriptions-item label="产品类型">{{
        dataildata.productType
      }}</el-descriptions-item>
      <el-descriptions-item label="网络制式">{{
        dataildata.networkType
      }}</el-descriptions-item>
      <el-descriptions-item label="机型">{{
        dataildata.productModelType
      }}</el-descriptions-item>
      <el-descriptions-item label="客诉类型">{{
        dataildata.keyword
      }}</el-descriptions-item>
      <el-descriptions-item label="客户提出时间">{{
        dataildata.createdTime
      }}</el-descriptions-item>
      <el-descriptions-item label="当前卡点">{{
        dataildata.stuckPoint
      }}</el-descriptions-item>
      <el-descriptions-item label="问题完成时间">{{
        dataildata.completedTime
      }}</el-descriptions-item>
      <el-descriptions-item label="转工站时间">{{
        dataildata.transformTime
      }}</el-descriptions-item>
      <!-- <el-descriptions-item label="反馈次数">{{
        dataildata.feedbackTimes
      }}</el-descriptions-item> -->
      ><el-descriptions-item :span="4" label="备注">{{
        dataildata.remark
      }}</el-descriptions-item>
      >
      <el-descriptions-item :span="4" label="问题进度">{{
        dataildata.solution
      }}</el-descriptions-item>
      <el-descriptions-item :span="4" label="问题描述">{{
        dataildata.description
      }}</el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
export default {
  data() {
    return {
      modelStatus: [
        { status: 0, text: "已解决客诉，并从根源解决", type: "info" }, // 1
        { status: 1, text: "已解决客诉，未从解决根源问题", type: "info" }, //
        { status: 2, text: "已解决客诉，未能找到根源问题", type: "info" }, //
      ],
    };
  },
  props: {
    dataildata: {},
  },

  mounted() {},

  methods: {},
};
</script>
<style lang="less" scoped>
.detile {
  padding: 20px;
  width: 100%;
  border-style: solid;
  border-width: 1px;
}
/deep/.el-descriptions-item__label {
  background: #77d5f4;
  font-weight: bolder;
  color: black;
}
</style>
