import request from "../utils/requst"



export const softwaresolve = data => {
    return request({
        method: 'POST',
        url: 'procedure/development-work-order/solve',
        data,
    })
}

export const softFinish = data => {
    return request({
        method: 'POST',
        url: 'procedure/development-work-order/complete',
        data,
    })
}

export const getOperatorListUrl = params => {
    return request({
        method: 'GET',
        url: 'procedure/development-work-order/getPersonLiableList',
        params,
    })
}

export const transformPersonLiableUrl = data => {
    return request({
        method: 'POST',
        url: 'procedure/development-work-order/transformPersonLiable',
        data,
    })
}


export const softwareformlist = params => {
    return request({
        method: 'GET',
        url: 'procedure/development-work-order/page',
        params,
    })
}

export const getOnesoftware = params => {
    return request({
        method: 'GET',
        url: 'procedure/development-work-order/get',
        params,
    })
}

export const transformUrl = data => {
    return request({
        method: 'POST',
        url: 'procedure/development-work-order/sendBack',
        data,
    })
}

export const DevelopExportExlUrl = params => {
    return request({
        method: 'GET',
        url: 'procedure/development-work-order/export',
        params,
        responseType: 'blob'
    })
}

export const platformExportExlUrl = params => {
    return request({
        method: 'GET',
        url: 'procedure/development-work-order/platformExport',
        params,
        responseType: 'blob'
    })
}

export const DevelopEditUrl = data => {
    return request({
        method: 'POST',
        url: 'procedure/development-work-order/edit',
        data,
    })
}

